import {
  getEventList,
  getEventDetails,
  putEvent,
  postEvent,
  deleteEvent,
  postEventPhoto,
  postEventCheckInAll,
  getEventBaptismReport,
  getEventSubscriptionExcelReport,
} from "src/api";

const list = (params) => getEventList(params);
const show = (id) => getEventDetails(id);
const update = (id, data) => putEvent(id, data);
const store = (data) => postEvent(data);
const destroy = (id) => deleteEvent(id);
const updatePhoto = (id, data) => postEventPhoto(id, data);
const checkInAll = (id) => postEventCheckInAll(id);
const baptismReport = (id) => getEventBaptismReport(id);
const subscriptionExcelReport = (id) => getEventSubscriptionExcelReport(id);

export const eventService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
  checkInAll,
  baptismReport,
  subscriptionExcelReport,
};
