import {
  getPersonList,
  getPersonDetails,
  putPerson,
  postPerson,
  deletePerson,
  getPersonValidateTaxId,
  postPersonPhoto,
  getPersonHistory,
  putPersonApprove,
  putPersonStatus,
  postPersonReportCustom,
} from "src/api";

const list = (params) => getPersonList(params);
const show = (id) => getPersonDetails(id);
const update = (id, data) => putPerson(id, data);
const store = (data) => postPerson(data);
const destroy = (id) => deletePerson(id);
const validateTaxId = (id) => getPersonValidateTaxId(id);
const updatePhoto = (id, data) => postPersonPhoto(id, data);
const history = (id) => getPersonHistory(id);
const approve = (id) => putPersonApprove(id);
const status = (id, data) => putPersonStatus(id, data);
const reportCustom = (data) => postPersonReportCustom(data);

export const personService = {
  list,
  show,
  update,
  store,
  destroy,
  validateTaxId,
  updatePhoto,
  history,
  approve,
  status,
  reportCustom,
};
