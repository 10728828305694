import axios from "axios";
const uuidv1 = require("uuid/v1");

function getToken() {
  let token = null;
  try {
    token = JSON.parse(localStorage.getItem("token")).access_token;
  } catch (e) {
    localStorage.removeItem("token");
  }
  return token;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  crossdomain: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (typeof config.headers.Authorization === "undefined") {
      var token = getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
        config.headers["X-Contract"] = localStorage.getItem("contract");
      }
    }

    config.headers["X-Transaction"] = uuidv1();

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (
      typeof error.response !== "undefined" &&
      error.response.status === 401
    ) {
      localStorage.removeItem("token");
      if (window.location.pathname !== "/auth/login") {
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);

export default instance;

export * from "./auth";
export * from "./church";
export * from "./person";
export * from "./utility";
export * from "./financialAccount";
export * from "./pdvTransaction";
export * from "./transaction";
export * from "./transactionCategory";
export * from "./ordination";
export * from "./paymentMethod";
export * from "./survey";
export * from "./closure";
export * from "./printable";
export * from "./dashboard";
export * from "./group";
export * from "./midia";
export * from "./music";
export * from "./event";
export * from "./user";
export * from "./receptionType";
export * from "./workflow";
export * from "./role";
