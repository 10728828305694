import {
  getMidiaList,
  getMidiaDetails,
  putMidia,
  postMidia,
  deleteMidia,
  postMidiaPhoto,
} from "src/api";

const list = (params) => getMidiaList(params);
const show = (id) => getMidiaDetails(id);
const update = (id, data) => putMidia(id, data);
const store = (data) => postMidia(data);
const destroy = (id) => deleteMidia(id);
const updatePhoto = (id, data) => postMidiaPhoto(id, data);

export const midiaService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
};
