import {
  getGroupList,
  getGroupDetails,
  putGroup,
  postGroup,
  deleteGroup,
  postGroupPhoto,
} from "src/api";

const list = (params) => getGroupList(params);
const show = (id) => getGroupDetails(id);
const update = (id, data) => putGroup(id, data);
const store = (data) => postGroup(data);
const destroy = (id) => deleteGroup(id);
const updatePhoto = (id, data) => postGroupPhoto(id, data);

export const groupService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
};
