import {
  getMusicList,
  getMusicDetails,
  putMusic,
  postMusic,
  deleteMusic,
  postMusicPhoto,
} from "src/api";

const list = (params) => getMusicList(params);
const show = (id) => getMusicDetails(id);
const update = (id, data) => putMusic(id, data);
const store = (data) => postMusic(data);
const destroy = (id) => deleteMusic(id);
const updatePhoto = (id, data) => postMusicPhoto(id, data);

export const musicService = {
  list,
  show,
  update,
  store,
  destroy,
  updatePhoto,
};
